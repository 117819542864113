<template>
  <section class="administration">
    <div class="container">
      <ShopList />
    </div>
  </section>
</template>

<script>
import ShopList from '../components/administration/ShopList';

export default {
  name: 'Administration',
  created: function () {
    this.$store.dispatch('shops/load');
  },
  components: {
    ShopList,
  },
};
</script>

<style>
</style>
<template>
  <transition name="fade">
    <div class="modalcontainer" >
      <div class="modal-flex" >
        <div class="modal active">
          <div class="content">
            <h2>{{ title }}</h2>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: ['title'],
  data: function () {
    return {};
  },

  methods: {
    ok: function () {
      this.$emit('close');
    },
    close: function () {
      this.$emit('close');
    },
  },
};
</script>

<style>
</style>
<template>
  <footer class="footer bg-dark py-5">
    <div class="container grid grid-3">
      <div>
        <h1>SimpleShop</h1>
        <p>Copyright &copy; 2021</p>
      </div>
      <nav>
        <ul>
          <li><router-link :to="{ name: 'Home' }">Inicio</router-link></li>
          <li>
            <router-link :to="{ name: 'Features' }">Galería</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Docs' }">Documentación</router-link>
          </li>
        </ul>
      </nav>
      <div class="social">
        <a href=""><i class="fab fa-github fa-2x"></i></a>
        <a href=""><i class="fab fa-facebook fa-2x"></i></a>
        <a href=""><i class="fab fa-instagram fa-2x"></i></a>
        <a href=""><i class="fab fa-twitter fa-2x"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>
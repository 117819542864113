<template>
  <div>
    <!-- Showcase -->
    <Showcase></Showcase>
    <!-- Stats -->
    <Stats></Stats>
    <!-- CLI -->
    <!-- <Cli></Cli> -->
    <!-- Cloud -->
    <Cloud></Cloud>
    <!-- Languages -->
   <!--  <Languages></Languages> -->
  </div>
</template>

<script>
import Showcase from '../components/home/Showcase';
import Stats from '../components/home/Stats';
////import Cli from '../components/home/Cli';
import Cloud from '../components/home/Cloud';
//import Languages from '../components/home/Languages';

export default {
  name: 'Home',
  components: {
    Showcase,
    Stats,
  //  Cli,
    Cloud,
   // Languages
  },
};
</script>

<style>
</style>
<template>
  <form class="ui form" v-on:submit="(e) => e.preventDefault()">
    <div class="field">
      <label>Nombre de la Tienda</label>
      <div class="ui large input">
        <input
          class="ui large"
          type="text"
          name="first-name"
          placeholder="Nombre de la tienda"
          v-model="localShop.name"
        />
      </div>
    </div>

    <h4 class="ui dividing header">Información del dominio</h4>
    <div class="fields">
      <div class="twelve wide field">
        <div class="ui right labeled large input">
          <label class="ui label" :class="domainStatusClass">https://</label>
          <input
            type="text"
            placeholder="Subdominio"
            v-model="localShop.subdomain"
            v-on:input="debounceInput"
          />
          <div class="ui label" :class="domainStatusClass">
            .simpleshop.com.mx
          </div>
        </div>
      </div>
    </div>
    <div class="fields" v-if="isAdmin">
      <div class="six wide field">
        <label>DNS Username</label>
        <input
          type="text"
          name="last-name"
          placeholder="DNS Username"
          v-model="localShop.dns.username"
        />
      </div>
      <div class="six wide field">
        <label>DNS Password</label>
        <input
          type="text"
          name="last-name"
          placeholder="DNS Password"
          v-model="localShop.dns.pass"
        />
      </div>
      <div class="four wide field">
        <label>IP</label>
        <input
          type="text"
          name="last-name"
          placeholder="Public IP"
          v-model="localShop.dns.ip"
        />
      </div>
    </div>
    <h4 class="ui dividing header" v-if="isAdmin">Estatus</h4>
    <div class="fields" v-if="isAdmin">
      <div class="five wide field">
        <label>Server</label>
        <select class="ui fluid dropdown" v-model="localShop.status.server">
          <option>WAITING</option>
          <option>READY</option>
          <option>COMPLETED</option>
          <option disabled value="ERROR">ERROR</option>
        </select>
      </div>
      <div class="five wide field">
        <label>Domain</label>
        <select class="ui fluid dropdown" v-model="localShop.status.domain">
          <option>WAITING</option>
          <option>READY</option>
          <option>COMPLETED</option>
          <option disabled value="ERROR">ERROR</option>
        </select>
      </div>
    </div>
    <h4 class="ui dividing header">Información de contacto</h4>
    <div class="fields">
      <div class="seven wide field">
        <label>Nombre</label>
        <input
          type="text"
          placeholder="Juan"
          v-model="localShop.contact.firstName"
        />
      </div>
      <div class="seven wide field">
        <label>Apellidos</label>
        <input
          type="text"
          placeholder="Perez"
          v-model="localShop.contact.lastName"
        />
      </div>
    </div>
    <div class="fields">
      <div class="seven wide field">
        <label>Email</label>
        <input
          type="text"
          placeholder="Email"
          v-model="localShop.contact.email"
        />
      </div>
      <div class="seven wide field">
        <label>Teléfono</label>
        <input
          type="text"
          placeholder="Teléfono"
          v-model="localShop.contact.phone"
        />
      </div>
    </div>
    <h4 class="ui dividing header"></h4>
    <button class="ui button" @click="cancel">Cancelar</button>
    <button class="ui primary button" @click="save">Guardar</button>
  </form>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ShopForm',
  props: ['shop'],
  data() {
    return {
      localShop: this.shop,
      isDomainValid: null,
      originalDomain: '',
    };
  },
  watch: {
    shop: function (shop, val) {
      this.originalDomain = shop.subdomain;
      this.isDomainValid = null;
      Object.assign(this.localShop, shop);
    },
  },
  computed: {
    domainStatusClass: function () {
      if (this.isDomainValid === true) {
        return 'green';
      } else if (this.isDomainValid === false) {
        return 'red';
      } else {
        return '';
      }
    },
    isAdmin: function () {
      if (
        this.$store.getters['auth/getPermissions'].find((p) => p === 'ADMIN')
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    save: function () {
      this.$emit('onSave', this.localShop);
    },
    cancel: function () {
      this.$emit('onCancel');
    },
    search: async function (subdomain) {
      if (subdomain && this.originalDomain !== subdomain.trim().toLowerCase()) {
        try {
          this.isDomainValid = await this.$store.dispatch(
            'shops/search',
            subdomain.trim().toLowerCase()
          );
          this.localShop.subdomain = subdomain.trim().toLowerCase();
        } catch (error) {
          console.log('Error validating subdomain');
        }
      } else {
        this.isDomainValid = null;
      }
    },
    debounceInput: _.debounce(function (e) {
      this.search(e.target.value);
    }, 500),
  },
};
</script>

<style>
</style>
<template>
  <div class="ui segment">
    <div class="ui center aligned very relaxed grid">
      <div class="column">
        <h2 class="ui blue  header">
          <div class="content">SimpleShop</div>
        </h2>
        <div v-if="message" class="ui warning message">
          <div class="header">
            Error iniciando sesión
          </div>
          <p>{{ message }}</p>
        </div>
        <div class="ui large form">
          <div class="ui center aligned stacked segment">
            <div class="field">
              <div class="ui left icon input">
                <i class="mail icon"></i>
                <input
                  type="text"
                  name="email"
                  placeholder="Correo electrónico"
                  autocomplete="nope"
                  v-model="email"
                />
              </div>
            </div>
            <div class="field">
              <div class="ui left icon input">
                <i class="lock icon"></i>
                <input
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  autocomplete="new-password"
                  v-model="password"
                  @keyup.enter="login()"
                />
              </div>
            </div>
            <div class="ui fluid large primary submit button" @click="login">
              Ingresar
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  props: ['message'],
  data: function() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    login: function() {
      if (this.email.trim() === '' || this.password.trim() === '') {
      } else {
        this.$emit('onLogin', {
          email: this.email,
          password: this.password
        });
      }
    }
  }
};
</script>

<style scoped="true" lang="css">
.column {
  max-width: 450px;
}
</style>

<template>
  <section class="features-sub-head bg-light py-3">
    <div class="container grid">
      <div>
        <h1 class="md">Simple Shop</h1>
        <p >
          Es una tienda en línea que te permite vender más de 1500 productos directamente a tus clientes,
          ofrece una amplia variedad de diseños y lo mejor es que no necesitas contar con inversión inicial
          ya que los productos se envían directamente a tu clientes.
        </p>
      </div>
      <img src="@/assets/images/server2.png" alt="Server" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'SubHead',
};
</script>

<style>
</style>
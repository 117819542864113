<template>
  <section class="showcase">
    <div class="container grid">
      <div class="showcase-text">
        <h1>¡Comienza tu negocio ahora!</h1>
        <p>
          Con Simple Shop comienza vendiendo increibles productos obteniendo
          ganacias de inmediato. La plataforma te permite administrar tus
          clientes y tus ganacias. ¡No necesitas invertir ni tener inventario
          físico!
        </p>
        <router-link class="btn btn-outline" :to="{ name: 'Features' }"
          >Saber más</router-link
        >
      </div>
      <div class="showcase-form card">
        <h2>Solicitar una demostración</h2>
        <form
          class="ui form"
          v-on:submit="(e) => e.preventDefault()"
          v-if="!demoRequested"
        >
          <div class="form-control">
            <input
              type="text"
              name="name"
              placeholder="Nombre"
              required
              v-model="this.demo.name"
            />
          </div>
          <div class="form-control">
            <input
              type="text"
              name="phone"
              placeholder="Teléfono"
              required
              v-model="this.demo.phone"
            />
          </div>
          <div class="form-control">
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              v-model="this.demo.email"
            />
          </div>
          <input
            type="button"
            class="ui primary button"
            value="Enviar"
            :class="{ disabled: !isRequestValid }"
            @click="sendRequest"
          />
        </form>
        <div v-else class="ui blue large message">Su solicitud ha sido enviada!</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Showcase',
  data: function () {
    return {
      demoRequested: false,
      demo: {
        name: '',
        email: '',
        phone: '',
      },
    };
  },
  computed: {
    isRequestValid: function () {
      if (
        !this.demoRequested &&
        this.demo.name.trim() &&
        this.demo.email.trim() &&
        this.demo.phone.trim()
      )
        return true;
      else return false;
    },
  },
  methods: {
    clearRequest: function () {
      this.demo = {
        name: '',
        email: '',
        phone: '',
      };
    },
    sendRequest: async function () {
      try {
        await this.$store.dispatch('demo/sendRequest', this.demo);
        this.demoRequested = true;
      } catch (error) {
        console.log('Error sending demo request');
      }
    },
  },
};
</script>

<style>
</style>

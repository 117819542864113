<template>
  <section class="cloud bg-primary my-2 py-2">
      <div class="container grid">
          <div class="text-center">
              <h2 class="lg">Envíos a todo México</h2>
              <p class="lead my-1">
                  Atiende a tus clientes desde tu computadora y brinda el mejor servicio
              </p>
              <a href="features.html" class="btn btn-dark">Saber más</a>
          </div>
          <img src="@/assets/images/cloud.png" alt="Cloud">
      </div>

  </section>
</template>

<script>
export default {
    name: 'Cloud'
}
</script>

<style>

</style>
<template>
  <div class="navbar">
    <div class="container flex">
      <h1 class="logo" v-if="isAuthenticated">SimpleShop - Administración</h1>
      <h1 v-else class="logo">SimpleShop</h1>
      <nav>
        <ul>
          <li><router-link :to="{ name: 'Home' }">Inicio</router-link></li>
          <li>
            <router-link :to="{ name: 'Features' }">Galería</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Docs' }">Documentación</router-link>
          </li>
          <li v-if="isAuthenticated"><a href="#" @click="logout">Logout</a></li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    isAuthenticated: function () {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="administration">
    <div>
      <Statistics :stats="stats" />
      <table class="ui striped selectable inverted table">
        <thead>
          <tr>
            <th class="collapsing">#</th>
            <th>Tienda</th>
            <th>URL Tienda</th>
            <th class="collapsing">Pago Mes</th>
            <th class="collapsing">Estatus</th>
            <th class="collapsing">Server</th>
            <th class="collapsing">Domain</th>
            <th class="collapsing">Fecha Alta</th>
            <th class="" colspan="3">
              <button class="ui green fluid mini button" @click="add">Agregar</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(shop, index) in shops" v-bind:key="shop.shop">
            <td>{{ index + 1 }}</td>
            <td>{{ shop.name }}</td>
            <td>
              <a class="text-light" :href="getShopUrl(shop.subdomain)" target="_blank">{{
                getShopUrl(shop.subdomain)
              }}</a>
              <br />
              <a class="text-light" :href="getAdminUrl(shop.subdomain)" target="_blank">{{
                getAdminUrl(shop.subdomain)
              }}</a>
            </td>
            <td class="collapsing">
              <div
                class="ui small label"
                :class="getPaymentsClass(shop.subscriptionStatus)"
              >
                {{ getPaymentStatus(shop.subscriptionStatus) }}
              </div>
            </td>
            <td>
              <div class="ui small label" :class="[shop.enabled ? 'green' : 'yellow']">
                {{ getShopStatus(shop.enabled) }}
              </div>
            </td>
            <td class="collapsing">
              <div class="ui small label" :class="getLabelColor(shop.status.server)">
                {{ shop.status.server }}
              </div>
            </td>
            <td class="collapsing">
              <div class="ui small label" :class="getLabelColor(shop.status.domain)">
                {{ shop.status.domain }}
              </div>
            </td>

            <td class="collapsing">{{ getFormattedDate(shop.createdOn) }}</td>
            <td class="collapsing">
              <div class="ui buttons">
                <button class="ui blue mini icon button" @click="edit(shop._id)">
                  <i class="edit icon"></i>
                </button>
                <button
                  class="ui orange mini icon button"
                  @click="openPayments(shop._id)"
                >
                  <i class="chart bar icon"></i>
                </button>
                <button class="ui mini icon button" @click="showLog(shop._id)">
                  <i class="list icon"></i>
                </button>
                <button
                  class="ui mini icon button"
                  :class="[shop.enabled ? 'green' : 'yellow']"
                  @click="toggleStatus(shop._id)"
                >
                  <i class="toggle on icon"></i>
                </button>
                <button class="ui mini red icon button" @click="deleteShop(shop._id)">
                  <i class="trash on icon"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Modal v-show="visible" title="Agregar o editar Tienda" @close="close">
      <ShopForm :shop="tmpShop" @onSave="save" @onCancel="close" />
    </Modal>
    <Modal v-show="showEvents" title="Eventos" @close="closeLog">
      <LogViewer :events="tmpShop.events" @onClose="closeLog" />
    </Modal>
    <Modal v-show="showPayments" title="Pagos de Mensualidad" @close="closePay">
      <Payments
        :payments="tmpShop.payments"
        @onClose="closePay"
        @addPayment="addPayment"
      />
    </Modal>
    <Modal v-show="showToggleModal" title="Confirmar">
      <div class="">
        <h3>Estas seguro que deseas cambiar estatus de la tienda?</h3>
        <br />
        <div class="ui right aligned">
          <button class="ui primary button" @click="confirmToggle">Confirmar</button>
          <button class="ui button" @click="cancelToggle">Cancelar</button>
        </div>
      </div>
    </Modal>
    <Modal v-show="showDeleteModal" title="Confirmar - Eliminar">
      <div class="">
        <h3>Estas seguro que deseas eliminar esta tienda?</h3>
        <br />
        <div class="ui right aligned">
          <button class="ui primary button" @click="confirmDelete">Confirmar</button>
          <button class="ui button" @click="cancelDelete">Cancelar</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";

import Modal from "../app/Modal";
import ShopForm from "./ShopForm";
import LogViewer from "./LogViewer";
import Payments from "./Payments";
import Statistics from "./Statistics";

export default {
  name: "ShopList",
  data: function () {
    return {
      visible: false,
      showEvents: false,
      showPayments: false,
      showToggleModal: false,
      showDeleteModal: false,
      targetId: "",
      tmpShop: {
        name: "",
        subdomain: "",
        contact: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        dns: {
          username: "",
          pass: "",
        },
        events: [],
        payments: [],
        enabled: true,
        status: {
          server: "WAITING",
          domain: "WAITING",
        },
      },
    };
  },
  computed: {
    shops: function () {
      return this.$store.getters["shops/getPageContent"];
    },
    stats: function () {
      const payed = this.shops.filter((s) => s.subscriptionStatus === "PAYED");
      const pending = this.shops.filter((s) => s.subscriptionStatus === "PENDING");
      return { payed: payed.length, pending: pending.length };
    },
  },

  methods: {
    getShopUrl: function (subdomain) {
      return `https://${subdomain}.simpleshop.com.mx`;
    },
    getAdminUrl: function (subdomain) {
      return `https://${subdomain}.simpleshop.com.mx/administration`;
    },
    getLabelColor: function (status) {
      if (status === "READY") {
        return "blue";
      } else if (status === "COMPLETED") {
        return "green";
      } else if (status === "ERROR") {
        return "red";
      } else {
        return "";
      }
    },
    getFormattedDate: function (target) {
      return moment(target).format("DD/MM/yyyy");
    },
    getPaymentStatus: function (status) {
      if (status === "PAYED") {
        return "Pagado";
      } else {
        return "Pendiente";
      }
    },
    getPaymentsClass: function (status) {
      if (status === "PAYED") {
        return "green";
      } else {
        return "yellow";
      }
    },
    getShopStatus: function (status) {
      if (status) {
        return "Habilitada";
      } else {
        return "Deshabilitada";
      }
    },
    add: function () {
      this.tmpShop = {
        _id: "",
        name: "",
        subdomain: "",
        contact: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        dns: {
          username: "",
          pass: "",
        },
        events: [],
        payments: [],
        ip: "",
        enabled: true,
        status: {
          server: "WAITING",
          domain: "WAITING",
        },
      };
      this.visible = !this.visible;
    },
    edit: async function (id) {
      try {
        this.loadShop(id);
        this.visible = !this.visible;
      } catch (error) {
        console.log(error);
        console.log("Error loading shop");
      }
    },
    close: function () {
      this.visible = false;
    },
    loadShop: async function (id) {
      try {
        this.tmpShop = await this.$store.dispatch("shops/getById", id);
      } catch (error) {
        console.log("Error loading shop");
      }
    },
    showLog: function (id) {
      this.loadShop(id);
      this.showEvents = !this.showEvents;
    },
    closeLog: function () {
      this.showEvents = !this.showEvents;
    },
    openPayments: function (id) {
      this.loadShop(id);
      this.showPayments = !this.showPayments;
    },
    closePay: function () {
      this.showPayments = !this.showPayments;
    },
    addPayment: async function (payment) {
      try {
        this.tmpShop = await this.$store.dispatch("shops/addPayment", {
          shopId: this.tmpShop._id,
          effectiveMonth: payment.effectiveMonth,
          effectiveYear: payment.effectiveYear,
          amount: payment.amount,
        });
      } catch (error) {
        console.log("Error creating payment");
      }
    },
    save: async function (shop) {
      if (shop._id) {
        try {
          const result = await this.$store.dispatch("shops/update", shop);
        } catch (error) {
          console.log("Error updating shop");
        }
      } else {
        try {
          delete shop._id;
          const result = await this.$store.dispatch("shops/create", shop);
        } catch (error) {
          console.log("Error creating shop");
        }
      }
      this.visible = false;
    },
    toggleStatus: function (shopId) {
      this.targetId = shopId;
      this.showToggleModal = true;
    },
    confirmToggle: async function () {
      try {
        const result = await this.$store.dispatch("shops/toggle", this.targetId);
      } catch (error) {
        console.log(error);
      }
      this.showToggleModal = false;
    },
    cancelToggle: function () {
      this.showToggleModal = false;
    },

    deleteShop: function (shopId) {
      this.targetId = shopId;
      this.showDeleteModal = true;
    },
    confirmDelete: async function () {
      try {
        const result = await this.$store.dispatch("shops/delete", this.targetId);
      } catch (error) {
        console.log(error);
      }
      this.showDeleteModal = false;
    },
    cancelDelete: function () {
      this.showDeleteModal = false;
    },
  },
  components: {
    Modal,
    ShopForm,
    LogViewer,
    Payments,
    Statistics,
  },
};
</script>

<style></style>

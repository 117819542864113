<template>
    <section class="stats">
        <div class="container">
            <h3 class="stats-heading text-center my-1">
                Simple Shop es la plataforma donde puedes iniciar tu negocio vendiendo por internet
            </h3>

            <div class="grid grid-3 text-center my-4">
                <div>
                    <i class="fas fa-store-alt fa-3x"></i>
                    <h3>+200</h3>
                    <p class="text-secondary">
                        Tiendas en Linea
                    </p>
                </div>
                <div>
                    <i class="fas fa-gift fa-3x"></i>
                    <h3>+1500</h3>
                    <p class="text-secondary">
                        Productos disponibles
                    </p>
                </div>
                <div>
                    <i class="fas fa-handshake fa-3x"></i>
                    <h3>+1000</h3>
                    <p class="text-secondary">
                        Clientes satisfechos
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Stats'
}
</script>

<style>

</style>
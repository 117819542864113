<template>
  <div>
      <Head />
      <SubHead />
      <Main />
  </div>
</template>

<script>
import Head from '../components/features/Head';
import SubHead from '../components/features/SubHead';
import Main from '../components/features/Main';
export default {
    name: 'Features',
    components: {
      Head,
      SubHead,
      Main
    }
}
</script>

<style>

</style>